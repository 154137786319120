import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { useProjectIntegrations } from "@pages/project-details/project-integrations/use-project-integrations";
import { NoIntegration } from "@pages/integrations/no-integration";
import { IntegrationSection } from "@pages/integrations/integration-section";
import {
  IntegrationActions,
  IntegrationSections,
} from "@pages/integrations/integrations-types";
import { ProjectIntegrationsDialog } from "@pages/project-details/project-integrations/project-integrations-dialog";
import { useState } from "react";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useToast } from "@hooks/use-toast";
import { IntegrationProject } from "@services/integrations-service/integrations-service-types";
import { ProjectEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

/** Renders project integration page */
export function ProjectIntegrations({
  projectId,
}: BaseProjectIdProps): JSX.Element {
  const { handleErrorWithToast } = useErrorContext();
  const { showToast } = useToast();
  const { trackEvent } = useTrackEvent();

  const [selectedIntegrationId, setSelectedIntegrationId] =
    useState<SphereDashboardAPITypes.IntegrationId>(
      SphereDashboardAPITypes.IntegrationId.autodesk
    );
  const [selectedAction, setSelectedAction] =
    useState<
      Extract<IntegrationActions, "ConnectToProject" | "DisconnectFromProject">
    >();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    connectProject,
    disconnectProject,
    enabledWorkspaceIntegrations,
    enabledProjectIntegrations,
    shouldShowConnectedDialog,
    setShouldShowConnectedDialog,
    getProjectIntegrationName,
  } = useProjectIntegrations({
    projectId,
  });

  /** Integrations that are not connected to the project */
  const availableIntegrations = enabledWorkspaceIntegrations.filter(
    (integration) => !enabledProjectIntegrations.includes(integration)
  );

  function onIntegrationActionClicked(
    integrationId: SphereDashboardAPITypes.IntegrationId,
    action: Extract<
      IntegrationActions,
      "ConnectToProject" | "DisconnectFromProject"
    >
  ): void {
    setSelectedIntegrationId(integrationId);
    setSelectedAction(action);
  }

  async function onConnectConfirm(
    selectedIntegrationProject: IntegrationProject
  ): Promise<void> {
    trackEvent({
      name: ProjectEvents.connectIntegration,
      props: { integrationId: selectedIntegrationId },
    });

    try {
      setIsLoading(true);
      await connectProject(selectedIntegrationProject);

      setSelectedAction(undefined);
      setShouldShowConnectedDialog(true);
    } catch (error) {
      handleErrorWithToast({
        id: `connectProject-${Date.now().toString()}`,
        title: `Error connecting project to ${IntegrationSections[selectedIntegrationId].displayName}`,
        error,
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function onDisconnectConfirm(): Promise<void> {
    trackEvent({
      name: ProjectEvents.disconnectIntegration,
      props: { integrationId: selectedIntegrationId },
    });

    try {
      setIsLoading(true);
      await disconnectProject(selectedIntegrationId);
      setSelectedAction(undefined);

      showToast({
        message: `${IntegrationSections[selectedIntegrationId].displayName} is disconnected from this project`,
        type: "success",
      });
    } catch (error) {
      handleErrorWithToast({
        id: `disconnectProject-${Date.now().toString()}`,
        title: `Error disconnecting project to ${IntegrationSections[selectedIntegrationId].displayName}`,
        error,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {enabledProjectIntegrations.length === 0 ? (
        <NoIntegration subject="project" />
      ) : (
        <IntegrationSection
          title="Connected"
          integrations={enabledProjectIntegrations}
          isConnected={true}
          enabledWorkspaceIntegrations={enabledWorkspaceIntegrations}
          actionButtonText="DisconnectFromProject"
          onIntegrationAction={(integrationId) =>
            onIntegrationActionClicked(integrationId, "DisconnectFromProject")
          }
          getProjectIntegrationName={getProjectIntegrationName}
        />
      )}

      {availableIntegrations.length !== 0 && (
        <IntegrationSection
          title="Available apps"
          integrations={availableIntegrations}
          actionButtonText="ConnectToProject"
          onIntegrationAction={(integrationId) =>
            onIntegrationActionClicked(integrationId, "ConnectToProject")
          }
        />
      )}

      <ProjectIntegrationsDialog
        selectedIntegrationId={selectedIntegrationId}
        onConnectConfirm={onConnectConfirm}
        isLoading={isLoading}
        shouldShowConnectedDialog={shouldShowConnectedDialog}
        setShouldShowConnectedDialog={setShouldShowConnectedDialog}
        onDisconnectConfirm={onDisconnectConfirm}
        selectedAction={selectedAction}
        onClose={() => setSelectedAction(undefined)}
      />
    </>
  );
}
