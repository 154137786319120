/**
 * Factory function to create an instance of UploadManager
 */

import { RemoveTaskFn, StartUploadFn, UpdateTaskFn, UploadManagerInterface } from "@custom-types/upload-manager-types";
import { UploadManager } from "@context-providers/file-upload/upload-manager";
import { WorkerUploadManager } from "@context-providers/file-upload/worker-upload-manager";

/**
 * Create an instance of UploadManager
 * @param startTaskInStore Function to start a background task in the store
 * @param updateTaskInStore Function to update the task in the store corresponding to the upload
 * @param removeTaskFromStore Function to remove a task from the store.
 * @returns Instance of an UploadManage
 */
export function createUploadManager(
    startTaskInStore: StartUploadFn,
    updateTaskInStore: UpdateTaskFn,
    removeTaskFromStore: RemoveTaskFn): UploadManagerInterface {
    try {
        return new WorkerUploadManager(
            startTaskInStore,
            updateTaskInStore,
            removeTaskFromStore
        );
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error("SharedWorker could not be initialized", error);
        // Fallback to the UploadManager if SharedWorker is not supported
    }
    return new UploadManager(
        startTaskInStore,
        updateTaskInStore,
        removeTaskFromStore
    );
}