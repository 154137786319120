import { assert } from "@faro-lotv/foundation";
import { GUID } from "@faro-lotv/ielement-types";
import { BackgroundTaskState } from "@faro-lotv/service-wires";
import {
  FileUploadParams,
  RemoveTaskFn,
  SharedWorkerMessage,
  SharedWorkerResponseMessage,
  isSharedWorkerResponseMessage,
  StartUploadFn,
  UpdateTaskFn,
  UploadManagerInterface,
  FileUploadCallbacks,
} from "@custom-types/upload-manager-types";

// Too many concurrent uploads can cause high CPU load, and requests might time out.
// Apparently 32 would be the optimum for a 7 Gbit connection, but that was measured using a native app.
export const MAX_CONCURRENT_UPLOADS = 16;

// Used for calculations from MiB to bytes and vice versa.
export const ONE_MEBIBYTE = 1_048_576;

// Minimum chunk size in bytes for parallel uploads. Defaults to 1MiB.
export const MINIMUM_CHUNK_SIZE = ONE_MEBIBYTE;

// Chunk size in bytes for parallel uploads. Defaults to 5MiB.
export const DEFAULT_CHUNK_SIZE = 5_242_880;


/**
 * This Mananger manages the connection to the shared worker, that actually handles the uploads.
 * 
 * WARNING: this class should not be used directly, it is just exported
 * as an implementation detail to realize the FileUploadContextProvider,
 * and the hooks useFileUpload and useCancelUpload.
 */
export class WorkerUploadManager implements UploadManagerInterface {
  // Map to store the callbacks for each upload
  #callbacks = new Map<GUID, FileUploadCallbacks>();

  // SharedWorker instance to handle the uploads
  #worker: SharedWorker | undefined;

  // Detect if the tab is in focus or not, to pause sending progress updates when the tab is not in focus
  #hasFocus = true;

  /**
   * @param startTaskInStore Function to start a background task in the store
   * @param updateTaskInStore Function to update the task in the store corresponding to the upload
   * @param removeTaskFromStore Function to remove a task from the store.
   */
  constructor(
    public startTaskInStore: StartUploadFn,
    public updateTaskInStore: UpdateTaskFn,
    public removeTaskFromStore: RemoveTaskFn
  ) {

    // Detect if the tab is in focus or not, to pause sending progress updates when the tab is not in focus
    globalThis.onfocus = () => {
      this.#hasFocus = true;
    };    
    globalThis.onblur = () => {
      this.#hasFocus = false;
    };    

    const workerUrl = new URL("./shared-worker.ts", import.meta.url);
    // throws an error if SharedWorker is not supported by the browser or disallowed for some reason
    this.#worker = new SharedWorker(workerUrl, { name: "XG-Upload-Worker", type: "module" });
    this.setChunkSize(DEFAULT_CHUNK_SIZE);
    this.setMaxConcurrentUploads(MAX_CONCURRENT_UPLOADS);
    this.#worker.port.onmessage = (event: MessageEvent) => {
      const message: SharedWorkerResponseMessage = event.data;

      if (!isSharedWorkerResponseMessage(message)) {
        /* eslint-disable no-console */
        console.warn("Invalid message received in shared worker:", event.data);
        return;
      }
      switch (message.callbackType) {
        case "onProgress": {
          if (this.#hasFocus) {
            this.uploadUpdated(message.arg as { id: GUID; progress: number; expectedEnd: number; speedMbit: number });
          }
          break;
        }
        case "onComplete": {
          this.uploadCompleted(message.arg as { id: GUID; downloadUrl: string; md5: string });
          break;
        }
        case "onCanceled": {
          this.onUploadCanceled(message.arg as { id: GUID; fileName: string });
          break;
        }
        case "onError": {
          this.uploadFailed(message.arg as { id: GUID; fileName: string; error: Error });
          break;
        }
      }
    };

    this.#worker.port.start();
  }

  /**
   * Sends a message to the shared worker
   *
   * @param message The message to send to the worker
   */
  #sendMessageToWorker(message: SharedWorkerMessage): void {
    this.#worker?.port.postMessage(message);
  }

  /**
   * Used to remove all callbacks for a given upload
   *
   * @param id ID of the upload to remove
   */
  #removeCallbacks(id: GUID): void {
    if (this.#callbacks.has(id)) {
      this.#callbacks.delete(id);
    }
  }

  /**
   * Callback function triggered when an upload is completed
   * Marks upload task as succeeded
   * Calls the finalizer if it exists
   * Removes the listener and process for the upload
   *
   * @param arg the arguments
   * @param arg.id ID of the upload that completed
   * @param arg.downloadUrl URL containing the uploaded file at the remote location.
   */
  private uploadCompleted(arg: { id: GUID; downloadUrl: string; md5: string }): void {
    
    const callbacks = this.#callbacks.get(arg.id);
    if (callbacks?.finalizer) {
      // the Finalizer cannot be passed to the SharedWorker, so we have to call it here instead
      callbacks?.finalizer?.(arg.downloadUrl, arg.md5).then(() => {
        this.updateTaskInStore(arg.id, { status: BackgroundTaskState.succeeded });
        this.#removeCallbacks(arg.id);
      }).catch((error) => {
        this.uploadFailed({ id: arg.id, fileName: "", error });
      });
    } else {
      this.updateTaskInStore(arg.id, { status: BackgroundTaskState.succeeded });
      this.#removeCallbacks(arg.id);
    }
  }

  /**
   * Callback function triggered when an upload was canceled
   * Marks upload task as aborted
   * Removes the listener and process for the upload
   *
   * @param arg the arguments
   * @param arg.id ID of the upload that completed
   * @param arg.fileName Name of the file that was canceled
   */
  private onUploadCanceled(arg: { id: GUID; fileName: string }): void {
    this.updateTaskInStore(arg.id, { status: BackgroundTaskState.aborted });

    const callbacks = this.#callbacks.get(arg.id);
    callbacks?.onUploadCanceled?.(arg.id, arg.fileName);

    this.#removeCallbacks(arg.id);
  }

  /**
   * Callback function triggered when an upload is failed
   * Marks upload task as failed
   * Removes the listener and process for the upload
   *
   * @param arg the arguments
   * @param arg.id ID of the upload that failed
   * @param arg.error Error thrown that made the upload to fail.
   */
  private uploadFailed(arg: { id: GUID; fileName: string, error: Error }): void {
    this.updateTaskInStore(arg.id, {
      status: BackgroundTaskState.failed,
      errorMessage: arg.error.message,
    });

    const callbacks = this.#callbacks.get(arg.id);
    callbacks?.onUploadFailed?.(arg.id, arg.fileName, arg.error);

    this.#removeCallbacks(arg.id);
  }

  /**
   * Callback function triggered when an upload got progress
   *
   * @param arg the arguments
   * @param arg.id ID of the upload that failed
   * @param arg.progress Current progress of the given upload, from 0 to 100
   * @param arg.expectedEnd Expected end timestamp of this task
   */
  private uploadUpdated(arg: {
    id: GUID;
    progress: number;
    expectedEnd: number;
    speedMbit: number;
  }): void {
    console.log("uploadUpdated", arg.id, arg.progress);
    this.updateTaskInStore(arg.id, {
      progress: arg.progress,
      status: BackgroundTaskState.started,
      expectedEnd: arg.expectedEnd,
      speedMbit: arg.speedMbit,
    });

    const callbacks = this.#callbacks.get(arg.id);
    callbacks?.onUploadUpdated?.(arg.id, arg.progress);
  }

  /**
   * Starts a new file upload and adds it to the managed uploads.
   */
  startFileUpload({
    file,
    isSilent = false,
    coreApiClient,
    finalizer,
    onUploadCompleted,
    onUploadFailed,
    onUploadUpdated,
    onUploadCanceled,
    context,
  }: FileUploadParams): void {
    const id = crypto.randomUUID();

    this.#callbacks.set(id, {
      finalizer,
      onUploadCompleted,
      onUploadFailed,
      onUploadUpdated,
      onUploadCanceled,
    });

    // Update the store with the new task
    this.startTaskInStore(id, file, isSilent, context);

    // Set the state to scheduled
    this.updateTaskInStore(id, { status: BackgroundTaskState.created });

    // Start the upload in the worker
    this.#sendMessageToWorker({ requestType: "startFileUpload", id, params: { file, context } });
  }

  /**
   * Cancels a file upload
   *
   * @param id The ID of the corresponding background task in the store
   * @param shouldRemoveTaskFromStore True to remove the task from store, primarily when all uploads are aborted.
   * @returns Whether the upload existed and was in progress, therefore canceled correctly.
   */
  cancelFileUpload(id: GUID, shouldRemoveTaskFromStore: boolean = false): boolean {
    if (shouldRemoveTaskFromStore) {
      this.removeTaskFromStore(id);
    }
    this.#sendMessageToWorker({ requestType: "cancelFileUpload", id });
    this.updateTaskInStore(id, { status: BackgroundTaskState.aborted });
    return true;
  }

  /**
   * Sets the maximum number of concurrent file uploads.
   * @param value Integer >= 1.
   */
  setMaxConcurrentUploads(value: number): void {
    assert(
      Number.isInteger(value) && value >= 1,
      "The maximum number of concurrent uploads must be an integer >= 1."
    );
    this.#sendMessageToWorker({ requestType: "setMaxConcurrentUploads", value });
  }

  /**
   * Set the chunk size for chunked upload in bytes.
   * @param value Integer > 1_048_576
   */
  setChunkSize(value: number): void {
    assert(
      Number.isInteger(value) && value >= MINIMUM_CHUNK_SIZE,
      `The chunk size must be an integer >= ${MINIMUM_CHUNK_SIZE}}.`
    );
    this.#sendMessageToWorker({ requestType: "setChunkSize", value });
  }
}
