import { GUID } from "@faro-lotv/foundation";
import { ApiClient } from "@stellar/api-logic";
import {
  FileUploadTask,
  FileUploadTaskContext,
} from "@custom-types/file-upload-types";
import { FinalizerCallback } from "@faro-lotv/service-wires";

/**
 * Callback type reporting that the upload has completed successfully
 *
 * @param id The ID the upload task has in the store.
 * @param fileName The name of the file
 * @param fileSize The size of the file
 * @param fileType The type of the file
 * @param downloadUrl The URL of the uploaded file at the remote location
 * @param md5 The hash of the uploaded file
 */
export type UploadCompletedCallback = (
  id: GUID,
  fileName: string,
  fileSize: number,
  fileType: string,
  downloadUrl: string,
  md5: string
) => void;

/**
 * Callback type reporting that the upload has failed
 *
 * @param id is the ID the upload task has in the store.
 * @param fileName is the name of the file.
 * @param error is the exception that was raised and made the upload fail.
 */
export type UploadFailedCallback = (
  id: GUID,
  fileName: string,
  error: Error
) => void;

/**
 * Callback type reporting that the upload has progressed
 *
 * @param id is the ID the upload task has in the store.
 * @param progress is the new progress of the upload, measured from 0 to 100.
 */
export type UploadUpdatedCallback = (id: GUID, progress: number) => void;

/**
 * Callback type reporting that the upload was canceled
 *
 * @param id is the ID the upload task has in the store.
 * @param fileName is the name of the file.
 */
export type UploadCanceledCallback = (id: GUID, fileName: string) => void;

/**
 *  Function used to notify an upload started to the React context to update the store
 *
 * @param id is the id of the task in the store
 * @param file is the handle to the file to upload
 * @param isSilent whether progress toast notifications should be hidden
 * @param context Additional information about the task
 */
export type StartUploadFn = (
  id: GUID,
  file: File,
  isSilent: boolean,
  context: FileUploadTaskContext
) => void;

/**
 * A type collecting the background task's properties that we may want
 * to update in the store
 */
export type UpdateTaskProps = Partial<
  Pick<FileUploadTask, "progress" | "expectedEnd" | "status" | "errorMessage" | "speedMbit">
>;

/** Function used to notify an upload was updated to the React context to update the store */
export type UpdateTaskFn = (id: GUID, propsToUpdate: UpdateTaskProps) => void;

/** Function used to notify an upload finished to the React context to remove the task from the store */
export type RemoveTaskFn = (id: GUID) => void;


/** All the callbacks that can be passed to the upload manager */
export type FileUploadCallbacks = {
  /** Callback called by the upload to finalize an upload. If it fails the upload is considered failed */
  finalizer?: FinalizerCallback;

  /**
   * Function called when a given file upload completes successfully.
   * This is executed after the finalizer.
   */
  onUploadCompleted?: UploadCompletedCallback;

  /** Function called when a given file upload fails */
  onUploadFailed?: UploadFailedCallback;

  /** Function called when the progress of a given upload changes */
  onUploadUpdated?: UploadUpdatedCallback;

  /** Function called when a given file upload is canceled */
  onUploadCanceled?: UploadCanceledCallback;
}

/** Necessary properties without callbacks */
export type FileUploadParamsOnly = {
  /** The file to upload */
  file: File;

  /** Additional information of the file upload task */
  context: FileUploadTaskContext;

  /** Whether to hide progress notifications to the user */
  isSilent?: boolean;

  /** Core API client to perform the upload */
  coreApiClient: ApiClient;
};

/** Necessary properties to upload file by upload manager */
export type FileUploadParams = FileUploadCallbacks & FileUploadParamsOnly;

/**
 * The interface of the object contained in the FileUploadsContext.
 *
 * WARNING: this type should not be used directly, it is just exported
 * as an implementation detail to realize the FileUploadContextProvider,
 * and the hooks useFileUploader and useCancelUpload.
 */
export interface UploadManagerInterface {
  /**
   * Starts a new file upload and adds it to the managed uploads.
   */
  startFileUpload(params: FileUploadParams): void;

  /**
   * @param id Id of the task to be canceled
   * @param shouldRemoveTaskFromStore True to remove the task from store, primarily when all uploads are aborted.
   * @returns Whether the task existed and was correctly canceled, i.e. was in progress.
   */
  cancelFileUpload(id: GUID, shouldRemoveTaskFromStore?: boolean): boolean;

  /**
   * Sets the maximum number of concurrent file uploads.
   * @param value Integer >= 1.
   */
  setMaxConcurrentUploads(value: number): void;

  /**
   * Set the chunk size for upload in bytes
   * @param value 
   */
  setChunkSize(value: number): void;
}

/** Callback types for shared worker messages */
export type SharedWorkerCallbackType = "onProgress" | "onComplete" | "onError" | "onCanceled" | "finalizer";

/** Dictionary with all types used as callback arguments */
export type CallbackArgType = { [key: string]: string | number | Error };

/** Response Messages of the shared worker. They are converted into callbacks */
export type SharedWorkerResponseMessage = {
  [key: string]: string | GUID | CallbackArgType;

  /** The type of the message */
  callbackType: SharedWorkerCallbackType;

  /** The id of the upload */
  id: GUID;

  /** The callback arguments also contains the id for simpler pass-through */
  arg: CallbackArgType;
};

/** Define the types of messages that can be sent to the shared worker */
export type SharedWorkerMessageType = "startFileUpload" | "cancelFileUpload" | "setMaxConcurrentUploads" | "setChunkSize";

/** Define FileUploadParamsReduced by omitting the coreApiClient */
export type FileUploadParamsReduced = Omit<FileUploadParamsOnly, "coreApiClient">;

/** A message to the shared worker */
export type SharedWorkerMessage = {
  [key: string]: string | FileUploadParamsReduced | boolean | number | undefined;

  /** The type of the message */
  requestType: SharedWorkerMessageType

  /** For "startFileUpload", "cancelFileUpload": the upload id the message is related to */
  id?: GUID;

  /** For startFileUpload */
  params?: FileUploadParamsReduced;

  /** For setMaxConcurrentUploads and setChunkSize */
  value?: number;
}

/**
 * Type guard for SharedWorkerMessage
 */
export function isSharedWorkerMessage(obj: SharedWorkerMessage): obj is SharedWorkerMessage {
  const propertyExpected: { [key in SharedWorkerMessageType]: string[] } = {
    startFileUpload: ["id", "params"],
    cancelFileUpload: ["id"],
    setMaxConcurrentUploads: ["value"],
    setChunkSize: ["value"],
  };
  const propertyTypes = {
    id: "string",
    params: "object",
    value: "number",
  };

  return (
    typeof obj === "object" &&
    obj !== null &&
    typeof obj.requestType === "string" &&
    obj.requestType in propertyExpected &&
    propertyExpected[obj.requestType].every((prop) => {
      return prop in obj && typeof obj[prop] === propertyTypes[prop as keyof typeof propertyTypes];
    })
  );
}

/**
 * Type guard for SharedWorkerResponseMessage
 */
export function isSharedWorkerResponseMessage(obj: SharedWorkerResponseMessage): obj is SharedWorkerResponseMessage {
  return (
    typeof obj === "object" &&
    obj !== null &&
    typeof obj.callbackType === "string" &&
    ["onProgress", "onComplete", "onError", "onCanceled", "finalizer"].includes(obj.callbackType) &&
    typeof obj.id === "string" &&
    typeof obj.arg === "object"
  );
}
